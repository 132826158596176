.app-alerts {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);

    .app-alert-head {
        padding: 0.5rem;
        border-radius: 2rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background-color: #ffffff;

        .icon {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            background-size: cover;

            &[type="ERROR"] {
                background: #fc3232;
            }

            &[type="SUCCESS"] {
                background: #0cb100;
            }

            &[type="WARNING"] {
                background: #e58e2b;
            }

            &[type="HELP"] {
                background: #33abc4;
            }
        }

        .message {
            font-size: 0.9rem;
            margin: 0 1rem;
        }

        .close-alert {
            margin: 0 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                fill: #bcbcbc;
            }

            &:hover {
                svg {
                    fill: #9c9c9c;
                }
            }
        }
    }

    .app-alert-callback {
        display: flex;
        margin-top: 15px;

        .btn {
            padding: 1rem;
            background: #9c9c9c;
            cursor: pointer;
        }

        .success-btn {
            background-color: green;
            color: var(--glb-txt-primary);
            margin-right: 10px;
            border-radius: 10px;
            font-weight: bold;
        }

        .cancel-btn {
            background-color: red;
            color: var(--glb-txt-primary);
            margin-left: 10px;
            border-radius: 10px;
            font-weight: bold;
        }
    }
}